import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { $api } from 'services/service';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Student__Create() {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    let cId: any = query.get('c');

    let { id } = useParams();
    const [updateMode, setUpdateMode] = useState(false);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [classDetail, setClassDetail] = useState({
        name: '',
        facultyName: '',
        facultyId: ''
    });
    const [subjectTeaching, setSubjectTeaching] = useState([]);

    const [studentDetail, setStudentDetail] = useState({
        startDate: new Date(),
        classId: cId,
        isGraduated: false,
        userName: '',
        password: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        createLibAccount: false,
        hasIssue: false,
        issueDescription: ''
    });
    const [studentDetailTmp, setStudentDetailTmp] = useState({
        startDate: new Date(),
        classId: cId,
        isGraduated: false,
        userName: '',
        password: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        createLibAccount: false,
        hasIssue: false,
        issueDescription: ''
    });

    useEffect(() => {
        async function getStudentDetail(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.student.getDetail(id);
                if (res && res.result) {
                    setUpdateMode(true);
                    setStudentDetail(res.result);
                    let tmp = Object.assign({}, res.result);
                    setStudentDetailTmp(tmp);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                setUpdateMode(false);
            }
        }

        if (id && id !== 'them-moi') {
            getStudentDetail(id);
        }
    }, [updateMode]);

    useEffect(() => {
        //
        async function getClassDetail(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.class.getDetail(id);
                setClassDetail(res.result);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (cId && cId?.length) {
            getClassDetail(cId);
        }
    }, []);
    useEffect(() => {
        async function getSubjectTeaching(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectTeaching_GetByStudent(id);
                setSubjectTeaching(res.result);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (id && id !== 'them-moi') {
            getSubjectTeaching(id);
        }
    }, []);

    async function submit(e) {
        e.preventDefault();
        let res;
        try {
            if (studentDetail.userInternalId === '') {
                show_ErrMsg('Vui lòng nhập mã số sinh viên');
                return;
            }
            if (studentDetail.fullName === '') {
                show_ErrMsg('Vui lòng nhập tên sinh viên');
                return;
            }
            if (studentDetail.userName === '') {
                show_ErrMsg('Vui lòng nhập địa chỉ mail');
                return;
            }
            if (studentDetail.userName !== '') {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(studentDetail.userName)) {
                    show_ErrMsg('Thông tin mail không đúng định dạng');
                    return;
                }
            }
            if (!studentDetail.classId || studentDetail.classId.length <= 0) {
                // @ts-ignore
                studentDetail.classId = cId;
            }
            dispatch(setLoading(true));
            if (updateMode) {
                res = await $api.student.updateStudent(studentDetail);
            } else {
                res = await $api.student.createStudent(studentDetail);
            }
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                history.push(`/sinh-vien?c=` + studentDetail.classId);
            } else if (res && res.exceptionMessage) {
                show_ErrMsg(res.exceptionMessage);
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function submitBlock(e) {
        e.preventDefault();
        let res;
        try {
            res = await $api.student.blockStudent({
                studentId: id,
                hasIssue: studentDetail.hasIssue,
                issueDescription: studentDetail.hasIssue ? studentDetail.issueDescription : ''
            });
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                history.push(`/sinh-vien?c=` + studentDetail.classId);
            } else if (res && res.exceptionMessage) {
                show_ErrMsg(res.exceptionMessage);
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function cancel() {
        setStudentDetail(studentDetailTmp);
        // history.push(`/sinh-vien?c=` + studentDetail.classId);
        history.goBack();
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    // @ts-ignore
    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <a href={`/lop-hoc?f=${classDetail?.facultyId}`}>
                                {classDetail?.facultyName}
                            </a>
                            <div className='___title__box__last'>{`>`}</div>
                            <a
                                href={`/sinh-vien?c=${cId}&f=${classDetail?.facultyId}`}>
                                {classDetail?.name}
                            </a>
                            <div className='___title__box__last'>{`>`}</div>
                            {updateMode
                                ? `${studentDetail.fullName} (${studentDetail.userInternalId})`
                                : 'Thêm mới sinh viên'}
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='container py-4'>
                        <div className='label-form font-bold'>
                            Thông tin sinh viên:
                        </div>
                        <div>
                            <div
                                className='mt-4 flex item-center flex-row flex-wrap justify-center w-full'
                                onSubmit={(e) => submit(e)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && submit(e)
                                }>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Mã số sinh viên
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập mã số sinh viên'
                                        value={
                                            studentDetail.userInternalId || ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                userInternalId: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tên sinh viên
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập tên sinh viên'
                                        value={studentDetail.fullName || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                fullName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Địa chỉ email
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        placeholder='Nhập địa chỉ email'
                                        value={studentDetail.userName || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                userName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Số điện thoại
                                    </div>
                                    <input
                                        name='text'
                                        type='tel'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập số điện thoại'
                                        value={studentDetail.mobile || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                mobile: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        CMND/CCCD
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập chứng minh thư'
                                        value={
                                            studentDetail.identificationNumber ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                identificationNumber:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Ngày sinh
                                    </div>
                                    <DatePicker
                                        className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        selected={
                                            new Date(studentDetail.birthDate)
                                        }
                                        onChange={(date) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                birthDate: date
                                            });
                                        }}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Ngày bắt đầu
                                    </div>
                                    <DatePicker
                                        className='appearance-none block
                                        w-full px-3 py-2 border border-gray-300
                                        placeholder-gray-500 rounded-md
                                        focus:outline-none focus:ring-1
                                        focus:ring-blue-700'
                                        selected={
                                            new Date(studentDetail.startDate)
                                        }
                                        onChange={(date) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                startDate: date
                                            });
                                        }}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </div>
                                <div className={'col-6 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tình trạng tốt nghiệp
                                    </div>
                                    <select
                                        className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        // @ts-ignore
                                        value={studentDetail.isGraduated}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                // @ts-ignore
                                                isGraduated:
                                                    e.target.value === 'true'
                                            });
                                        }}>
                                        <option
                                            // @ts-ignore
                                            value={false}
                                            // @ts-ignore
                                            key={false}>
                                            Chưa tốt nghiệp
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={true}
                                            // @ts-ignore
                                            key={true}>
                                            Đã tốt nghiệp
                                        </option>
                                    </select>
                                </div>

                                <div className='mt-4 flex flex-row justify-between col-12 '>
                                    <div className={'col-6'}>
                                        <div className={'flex flex-row justify-start'}>
                                            <label htmlFor={'libary'}
                                                   className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Tạo tài khoản thư viện cho học viên:
                                            </label>
                                            <input
                                                id={'libary'}
                                                type='checkbox'
                                                className='h-4 w-4 mt-0.5 ml-4'
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        // @ts-ignore
                                                        createLibAccount: e.target.checked
                                                    });
                                                }}
                                                // @ts-ignore
                                                checked={studentDetail.createLibAccount}
                                            /></div>
                                    </div>
                                    <div className={'col-6'}>
                                        {
                                            updateMode ? <div className={'flex flex-row justify-end w-full'}>
                                                    <form
                                                        className='flex item-center flex-col justify-center w-full'
                                                        onSubmit={(e) => submitBlock(e)}
                                                        onKeyDown={(e) =>
                                                            e.key === 'Enter' && submitBlock(e)
                                                        }>

                                                        <div className=' flex center'>
                                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                                Chặn tính năng trên app:
                                                            </div>
                                                            <input
                                                                type='checkbox'
                                                                className='h-4 w-4 mt-0.5 ml-4'
                                                                onChange={(e) => {
                                                                    setStudentDetail({
                                                                        ...studentDetail,
                                                                        // @ts-ignore
                                                                        hasIssue: e.target.checked
                                                                    });
                                                                }}
                                                                // @ts-ignore
                                                                checked={studentDetail.hasIssue}
                                                            />
                                                        </div>
                                                        {
                                                            studentDetail.hasIssue ?
                                                                <div>
                                                                    <div
                                                                        className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                                        Lý do chặn sinh viên:
                                                                    </div>
                                                                    <textarea
                                                                        name='block-reason'
                                                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                                        placeholder='Lý do...'
                                                                        value={studentDetail.issueDescription}
                                                                        onChange={(e) => {
                                                                            setStudentDetail({
                                                                                ...studentDetail,
                                                                                issueDescription: e.target.value
                                                                            });
                                                                        }}
                                                                    />
                                                                    <div className='flex justify-center'>
                                                                        <button
                                                                            type='submit'
                                                                            className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                                                            Lưu lại
                                                                        </button>
                                                                    </div>
                                                                </div> : ''
                                                        }
                                                    </form>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className='flex justify-center'>
                                    <button
                                        type='button'
                                        className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
                                        onClick={cancel}>
                                        Hủy Bỏ
                                    </button>
                                    <button
                                        type='submit'
                                        className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                        Lưu lại
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    updateMode ? <div className='page__content bg-white mt-3'>
                        <div className='container py-4'>
                            <div className='label-form'>
                                <div className={'py-4 font-bold'}>Danh sách môn học tham gia</div>
                                {
                                    subjectTeaching.length > 0 ? <table className='min-w-full divide-y divide-gray-200'>
                                            <thead className='bg-gray-50'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Khoa
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Tên môn
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Số tín chỉ
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Trạng thái
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
                                            {subjectTeaching.map((item) => (
                                                <tr
                                                    className='hover:bg-gray-100'
                                                    key={item.id}>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-left items-start text-sm text-gray-900'>
                                                            {item.subject.facultyName || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div className='flex items-start text-sm text-gray-900'>
                                                            {item.subject.name || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-center items-start text-sm text-gray-900'>
                                                            {item.subject.creditPoint || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-center items-start text-sm text-gray-900 font-bold'>
                                                            {item.subjectStatus || ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        : <div
                                            className={'mx-2 font-medium font-bold w-full flex flex-row justify-center'}> Chưa
                                            có thông tin môn học!</div>
                                }
                            </div>
                        </div>
                    </div> : ''
                }
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Student__Create;

import './side-menu.scss';
import { NavLink } from 'react-router-dom';
import { _AUTH } from '../../../constants/_auth';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function SideMenu(props) {
    let role = localStorage.getItem(_AUTH.ROLE) !== null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';
    return (
        <div
            className={classNames(
                'side-menu bg-white h-full shadow-sm side-menu--hidden lg:side-menu--full',
                props.hide ? '' : 'side-menu--full'
            )}>
            <div className='_wrapper'>
                {role ?
                    <><NavLink exact to='/' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-chalkboard-teacher text-xl' />
                            </div>
                            <div className='label font-semibold'>Khoa</div>
                        </div>
                    </NavLink>
                        <div className='space my-1' />
                        <NavLink exact to='/quan-ly-chung' activeClassName='active-link'>
                            <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                <div className='icon d-icon mr-8 opacity-100'>
                                    <i className='fas fa-users text-xl' />
                                </div>
                                <div className='label font-semibold'>Quản lý chung</div>
                            </div>
                        </NavLink>
                        <NavLink exact to='/quan-ly-sinh-vien' activeClassName='active-link'>
                            <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                <div className='icon d-icon mr-8 opacity-100'>
                                    <i className='fas fa-users-cog text-xl' />
                                </div>
                                <div className='label font-semibold'>Quản lý SV</div>
                            </div>
                        </NavLink>
                    </> : ''
                }
                <div className='space my-1' />
                <NavLink to='/thoi-khoa-bieu' activeClassName='active-link'>
                    <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                        <div className='icon d-icon mr-8 opacity-100'>
                            <i className='far fa-calendar-alt text-xl' />
                        </div>
                        <div className='label font-semibold'>Thời khóa biểu</div>
                    </div>
                </NavLink>
                {role ? <>
                    <div className='space my-1' />
                    <NavLink to='/thoi-khoa-bieu-lop' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-calendar text-xl' />
                            </div>
                            <div className='label font-semibold'>TKB Lớp</div>
                        </div>
                    </NavLink>
                    <div className='space my-1' />
                    <NavLink to='/ke-hoach-toan-khoa' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-calendar text-xl' />
                            </div>
                            <div className='label font-semibold'>Kế hoạch toàn khóa</div>
                        </div>
                    </NavLink>
                    <div className='space my-1' />
                    <NavLink to='/bieu-mau-xac-nhan' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='far fa-file-alt text-xl' />
                            </div>
                            <div className='label font-semibold'>Biểu mẫu, xác nhận</div>
                        </div>
                    </NavLink>
                    <div className='space my-1' />
                    <NavLink to='/audit-log' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-history text-xl' />
                            </div>
                            <div className='label font-semibold'>Audit Log</div>
                        </div>
                    </NavLink>
                </> : ''}
            </div>
        </div>
    );
}

export default SideMenu;

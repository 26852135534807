import axiosClient from 'config/axiosClient';

const _url = '/Api';
const scheduleService = {
    subjectSchedule_getAll: () => {
        return axiosClient.post(_url + '/SubjectSchedules/GetAll');
    },
    subjectSchedule_get: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/Get', { id: id });
    },
    subjectSchedule_getPaging: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetPaging', { subjectTeachingId: id });
    },
    subjectSchedule_getPagingOption: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/GetPaging', option);
    },
    subjectSchedule_create: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/Create', option);
    },
    subjectSchedule_createRepeat: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/CreateRepeat', option);
    },
    subjectSchedule_Update: (option) => {
        return axiosClient.post(_url + '/SubjectSchedules/Update', option);
    },
    subjectSchedule_Delete: (id) => {
        return axiosClient.post(_url + '/SubjectSchedules/Delete', { id: id });
    },
    subjectTeaching_getPaging: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetPaging', option);
    },
    subjectStudent_getPaging: (option) => {
        return axiosClient.post(_url + '/SubjectStudents/GetPaging', option);
    },
    subjectStudent_getStudentForSelect: (subjectTeachingId) => {
        return axiosClient.post(_url + '/SubjectStudents/GetStudentForSelect', { subjectTeachingId: subjectTeachingId });
    },
    subjectStudent_create: (data) => {
        return axiosClient.post(_url + '/SubjectStudents/Create', data);
    },
    subjectStudent_update: (data) => {
        return axiosClient.post(_url + '/SubjectStudents/Update', data);
    },
    subjectTeaching_create: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/Create', option);
    },
    subjectTeaching_update: (option) => {
        return axiosClient.post(_url + '/SubjectTeachings/Update', option);
    },
    subjectTeaching_delete: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/Delete', { id: id });
    },
    subjectTeaching_get: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/Get', { id: id });
    },
    subjectTeaching_createNotification: (data) => {
        return axiosClient.post(_url + '/SubjectTeachings/CreateNotification', data);
    },
    subjectSchedule_update: (data) => {
        return axiosClient.post(_url + '/SubjectSchedules/Update', data);
    },
    subjectTeachingsGetAttendances: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetAttendances', { id: id });
    },
    subjectSchedulesExport: (option) => {
        return axiosClient.get(_url + '/SubjectSchedules/Export', {
            params:option, responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    subjectTeaching_GetByStudent: (id) => {
        return axiosClient.post(_url + '/SubjectTeachings/GetByStudentId', {id: id});
    },
};

export default scheduleService;

import axiosClient from 'config/axiosClient';

const _url = '/Api/SemesterPlanning';
const semesterPlanningService = {
    getByClass: (id) => {
        return axiosClient.post(_url + '/GetByClass', {id: id });
    },
    create: (data) => {
        return axiosClient.post(_url + '/Create', data);
    },
    update: (data) => {
        return axiosClient.post(_url + '/Update', data);
    },
    delete: (id) => {
        return axiosClient.post(_url + '/Delete', {id: id});
    }
};

export default semesterPlanningService;

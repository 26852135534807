import Button from 'components/Elements/Button/Button';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import './faculties__list.scss';
import React, { useEffect, useRef, useState } from 'react';
import { $api } from 'services/service';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import Calendar from '../../../components/Elements/Calender/Calendar';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TableSubjectOnDay from '../../../components/Elements/Table/Table-Subject-OnDay';
import fileDownload from 'js-file-download';
import { _AUTH } from '../../../constants/_auth';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Subject__List() {
    let role = localStorage.getItem(_AUTH.ROLE) != null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';
    let history = useHistory();
    const ref = useRef();
    const query = useQuery();
    const dispatch = useDispatch();
    let faId: any = localStorage.getItem('faculty') ? localStorage.getItem('faculty') : query.get('f');

    const [subjects, setSubjects] = useState([]);
    const [subjectsDetails, setSubjectsDetails] = useState({
        isActive: false,
        data: { subjectSchedule: {}, subjectTeaching: {} }
    });
    const [teachers, setTeachers] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [classLists, setClassLists] = useState([]);
    const [modalShowSubjectSchedule, setModalShowSubjectSchedule] = useState(false);
    const [modalShowExportSubjectSchedule, setModalShowExportSubjectSchedule] = useState(false);
    const [subject, setSubject] = useState({ subjectCode: 'string', name: 'string', creditPoint: 0, facultyId: '' });
    const [faculties, setFaculties] = useState([]);
    const [subjectOnDay, setSubjectOnDay] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [freeTextSearch, setFreeTextSearch] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [changes, setChanges] = useState(false);
    const [updateMode, setUpdateMode] = useState(null);
    const [classSubjectSchedule, setClassSubjectSchedule] = useState('');
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [confirmDeleteSubjectDialog, setConfirmDeleteSubjectDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);
    const [isMonth, setIsMonth] = useState({
        start: new Date(),
        end: new Date()
    });
    const [calendarSubject, setCalendarSubject] = useState([{
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: '#1e3a8a',
        eventName: 'subject'
    }]);
    const [calendarExam, setCalendarExam] = useState([{
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: '#c42828',
        eventName: 'exam'
    }]);
    const [subjectTeaching, setSubjectTeaching] = useState({
        id: '',
        subjectId: '',
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '00:00',
        teacherId: '',
        roomIdDefault: ''
    });

    const [subjectScheduleTmp, setSubjectScheduleTmp] = useState({
        id: '',
        subjectTeachingId: '',
        subjectId: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '07:00',
        endTime: '07:00',
        roomId: '',
        isRepeatedWeekly: false
    });

    const [subjectTeachingDetail, setSubjectTeachingDetail] = useState({
        id: ''
        , name: ''
        , subjectId: ''
        , subject: []
        , startDate: new Date()
        , endDate: new Date()
        , teacherId: ''
        , roomIdDefault: ''
        , teacher: []
    });

    const [subjectScheduleExport, setSubjectScheduleExport] = useState(
        {
            startDate: new Date(),
            endDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
            teacherId: ''
        }
    );

    const [timeInput, setTimeInput] = useState({
        startTime: '07:30',
        endTime: '10:45'
    });
    useEffect(() => {
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.getPaging({});
                if (res && res.result['items'].length > 0) {
                    setFaculties(res.result.items);
                    if (faId && faId.length > 0) {
                        setFaculty(faId);
                    } else {
                        setFaculty(res.result.items[0].id);
                    }
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
            }
        }

        getFaculties();
    }, []);

    async function isRepeatedWeekly_Checked(e) {
        setSubjectScheduleTmp({
            ...subjectScheduleTmp,
            isRepeatedWeekly: e.target.checked
        });
    }


    async function getSubjectOnDate(dateStr) {
        if (faculty || faculty.length > 0) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectSchedule_getPagingOption({
                    'facultyId': faculty,
                    'date': dateStr
                });
                if (res && res.result) {
                    if (res.result.totalRecord > 0) {
                        setSubjectOnDay(res.result.items);
                        // @ts-ignore
                        ref.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'start'
                        });
                    } else {
                        setSubjectOnDay([]);
                    }
                    dispatch(setLoading(false));
                }
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }
    }


    useEffect(() => {
        history.push('/thoi-khoa-bieu?f=' + faculty);
        localStorage.setItem('faculty', faculty);
    }, [faculty]);

    useEffect(() => {
        getSubjects({
            facultyId: faculty || faculty.length > 0 ? faculty : null,
            freeTextSearch: freeTextSearch || freeTextSearch.length > 0 ? freeTextSearch : null
        });
    }, [changes, faculty]);

    async function getSubjects_GetLists() {
        const arraySubject = [];
        const arrayExam = [];
        if (faculty || faculty.length > 0) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.subject.subjectGetLists({
                    'facultyId': faculty,
                    'freeTextSearch': freeTextSearch,
                    'startTime': isMonth.start,
                    'endTime': isMonth.end
                });
                if (res && res.result) {
                    res.result.map((items: any) => {
                            if (items?.subjectTeachings.length > 0) {
                                items?.subjectTeachings.map((schedule: any) => {
                                    schedule.subjectSchedules.map((subject: any) => {
                                        arraySubject.push({
                                            // @ts-ignore
                                            id: subject.id,
                                            // @ts-ignore
                                            title: `<b>${items.name + ' - ' + schedule.name}</b><br/><span>${moment(subject.startDateTime + 'Z').format(
                                                'HH:mm'
                                            )} - ${moment(subject.endDateTime + 'Z').format(
                                                'HH:mm'
                                            )}</span>`,
                                            // @ts-ignore
                                            start: moment(subject.startDateTime + 'Z').format('YYYY-MM-DD'),
                                            // @ts-ignore
                                            end: moment(subject.endDateTime + 'Z').format('YYYY-MM-DD'),
                                            // @ts-ignore
                                            backgroundColor: Number.parseInt(moment(subject.startDateTime + 'Z').format('HH')) > 12 ? '#F8B050FF' : '#1e3a8a',
                                            // @ts-ignore
                                            eventName: 'subject',
                                            // @ts-ignore
                                            scheduleName: subject.name
                                        });
                                    });
                                    schedule.exams.map((item: any) => {
                                        arrayExam.push({
                                            // @ts-ignore
                                            id: item.id,
                                            // @ts-ignore
                                            title: `<b>${items.name + ' - ' + schedule.name}</b><br><b>${moment(item.startDate + 'Z').format(
                                                'HH:mm'
                                            )} - ${moment(item.endDate + 'Z').format(
                                                'HH:mm'
                                            )}</b>
						<br/><b>${'Lịch thi:' + item?.name}</b>`,
                                            // @ts-ignore
                                            start: moment(item.startDate + 'Z').format('YYYY-MM-DD'),
                                            // @ts-ignore
                                            end: moment(item.endDate + 'Z').format('YYYY-MM-DD'),
                                            // @ts-ignore
                                            backgroundColor: '#c42828',
                                            // @ts-ignore
                                            eventName: 'exam'
                                        });
                                    });
                                });
                            }

                        }
                    );
                    // @ts-ignore
                    setCalendarSubject(arraySubject);
                    // @ts-ignore
                    setCalendarExam(arrayExam);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }
    }


    useEffect(() => {
        setSubjectsDetails({ isActive: false, data: { subjectSchedule: {}, subjectTeaching: {} } });
        setCalendarSubject([{
            id: '',
            title: '',
            start: '',
            end: '',
            backgroundColor: '#1e3a8a',
            eventName: 'subject'
        }]);

        getSubjects_GetLists();
    }, [isMonth, faculty, changes]);

    async function getSubjects(option = {}) {
        try {
            dispatch(setLoading(true));
            if (faculty) {
                const res: any = await $api.subject.getPaging(option);
                if (res && res.result['items'].length > 0) {
                    setSubjects(res.result.items);
                    await getClass(res.result.items[0].id);
                } else if (res && res.result['items'].length === 0) {
                    setSubjects([]);
                }
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function getSubjectTeaching(id) {
        try {
            dispatch(setLoading(true));
            if (faculty) {
                const res: any = await $api.schedule.subjectSchedule_get(id);
                if (res && res.result) {
                    subjectTeachingDetail.endDate = res.result.endDateTime;
                    subjectTeachingDetail.name = '';
                    subjectTeachingDetail.roomIdDefault = res.result.roomId;
                    subjectTeachingDetail.startDate = res.result.startDateTime;
                    subjectTeachingDetail.subject = [];
                    subjectTeachingDetail.subjectId = res.result.subjectTeachingId;
                    subjectTeachingDetail.teacher = [];
                    subjectTeachingDetail.teacherId = '';
                    subjectTeachingDetail.id = res.result.id;
                    setSubjectTeachingDetail(subjectTeachingDetail);
                    setTimeInput({
                        endTime: moment(res.result.endDateTime + 'Z').format('HH:mm'),
                        startTime: moment(res.result.startDateTime + 'Z').format('HH:mm')
                    });
                    const resSubject: any = await $api.schedule.subjectTeaching_get(res.result.subjectTeachingId);
                    if (resSubject && resSubject.result) {
                        setTimeout(() => {
                            setSubjectsDetails({
                                isActive: true,
                                data: { subjectSchedule: res.result, subjectTeaching: resSubject.result }
                            });

                        }, 100);
                        await isCreateSubjectSchdule('');
                    }
                }
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function createSubject() {
        try {
            if (subject.name.length <= 0) {
                show_ErrMsg('Vui lòng nhập tên môn học');
                return;
            }
            if (subject.subjectCode.length <= 0) {
                show_ErrMsg('Vui lòng nhập mã môn học');
                return;
            }
            if (faculty.length <= 0) {
                show_ErrMsg('Vui lòng nhập thông tin khoa');
                return;
            }
            dispatch(setLoading(true));
            const res: any = await $api.subject.createSubject({
                name: subject.name,
                subjectCode: subject.subjectCode,
                creditPoint: subject.creditPoint,
                facultyId: faculty
            });
            if (res) {
                setSubject({
                    subjectCode: '',
                    name: '',
                    creditPoint: 0,
                    facultyId: ''
                });
                setChanges(!changes);
                dispatch(
                    setAlert({
                        type: 'success',
                        title: `Tạo thành công ${subject.name}`
                    })
                );
            }
            dispatch(setLoading(false));
            setModalShow(false);
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function goTeaching(e, id, facultyId) {
        e.stopPropagation();
        history.push(`/lich-hoc-mon?id=${id} & facultyId =${facultyId}`);
    }

    async function openUpdateSubject(e, object) {
        e.stopPropagation();
        try {
            setSubject(object);
            setUpdateMode(object.id);
            setModalShow(true);
        } catch (e) {
        }
    }

    async function openCreateSubject(e) {
        e.stopPropagation();
        try {
            setSubject({
                subjectCode: '',
                name: '',
                creditPoint: 0,
                facultyId: ''
            });
            setUpdateMode(null);
            setModalShow(true);
        } catch (e) {
        }
    }

    async function updateSubject(id) {
        try {
            if (subject.name.length <= 0) {
                show_ErrMsg('Vui lòng nhập tên môn học');
                return;
            }
            if (subject.subjectCode.length <= 0) {
                show_ErrMsg('Vui lòng nhập mã môn học');
                return;
            }
            dispatch(setLoading(true));
            const res: any = await $api.subject.updateSubject({
                id: id,
                name: subject.name,
                subjectCode: subject.subjectCode,
                creditPoint: subject.creditPoint,
                facultyId: subject.facultyId
            });
            if (res) {
                dispatch(
                    setAlert({
                        title: `
                                Cập;
                                nhật;
                                thành;
                                công ${subject.name}`
                    })
                );
                setSubject({
                    subjectCode: '',
                    name: '',
                    creditPoint: 0,
                    facultyId: ''
                });
                setChanges(!changes);
            }
            dispatch(setLoading(false));
            setModalShow(false);
            setUpdateMode(null);
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteFaculties(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subject.deleteSubject(idDelete);
            if (res) {
                setSubject({
                    subjectCode: '',
                    name: '',
                    creditPoint: 0,
                    facultyId: ''
                });
                dispatch(
                    setAlert({
                        type: 'success',
                        title: res.message
                    })
                );
                setChanges(!changes);
                setModalShow(false);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteSubjectSchedule() {
        setConfirmDeleteSubjectDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedule_Delete(subjectScheduleTmp.id);
            if (res) {
                dispatch(
                    setAlert({
                        type: 'success',
                        title: res.message
                    })
                );
                setModalShowSubjectSchedule(false);
                setTimeout(() => {
                    getSubjects_GetLists();
                }, 300);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            setConfirmDeleteSubjectDialog({ isOpen: false, title: '', subTitle: '' });
            setModalShowSubjectSchedule(false);
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function isDeleteSubjectSchedule() {
        setConfirmDeleteSubjectDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        // @ts-ignore
        setIdDelete(subjectScheduleTmp.id);
    }

    function setDatWithTime(date, time) {
        const hour = time.split(':')[0] || 9;
        const min = time.split(':')[1] || 0;
        if ((Number.parseInt(time) - 7) >= 10) {
            return moment(date).format('YYYY-MM-DD') + 'T' + (Number(hour) - 7) + ':' + min + ':00.000Z';
        } else {
            return moment(date).format('YYYY-MM-DD') + 'T' + '0' + (Number(hour) - 7) + ':' + min + ':00.000Z';
        }
    }

    async function onSaveSubjectSchedule() {
        if (!subjectScheduleTmp.isRepeatedWeekly) {
            if (setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        } else {
            if (setDatWithTime(subjectScheduleTmp.endDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        }

        try {
            dispatch(setLoading(true));
            const postData = {
                // @ts-ignore
                subjectTeachingId: subjectScheduleTmp.subjectTeachingId == '' ? classLists[0].id : subjectScheduleTmp.subjectTeachingId,
                startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                endDateTime: setDatWithTime(subjectScheduleTmp.isRepeatedWeekly ? subjectScheduleTmp.endDate : subjectScheduleTmp.startDate, timeInput.endTime),
                // @ts-ignore
                roomId: subjectScheduleTmp.roomId == '' ? rooms[0].id : subjectScheduleTmp.roomId
            };
            if (subjectScheduleTmp.isRepeatedWeekly) {
                const res: any = await $api.schedule.subjectSchedule_createRepeat(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            } else {
                const res: any = await $api.schedule.subjectSchedule_create([postData]);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            }
            setSubjectScheduleTmp({
                id: '',
                subjectTeachingId: '',
                subjectId: '',
                startDate: new Date(),
                endDate: new Date(),
                startTime: '07:00',
                endTime: '07:00',
                roomId: subjectTeachingDetail.roomIdDefault,
                isRepeatedWeekly: false
            });
            setTimeInput({
                startTime: '07:00',
                endTime: '07:00'
            });
            setModalShowSubjectSchedule(false);
            setChanges(!changes);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function onUpdateSubjectSchedule() {
        if (!subjectScheduleTmp.isRepeatedWeekly) {
            if (setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        } else {
            if (setDatWithTime(subjectScheduleTmp.endDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        }

        try {
            const postData = {
                // @ts-ignore
                id: subjectScheduleTmp.id !== null || '' ? subjectsDetails.data.subjectSchedule.id : subjectScheduleTmp.id,
                startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                endDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime),
                // @ts-ignore
                roomId: subjectScheduleTmp.roomId
            };
            const res: any = await $api.schedule.subjectSchedule_update(postData);
            if (res) {
                setTimeout(() => {
                    getSubjects_GetLists();
                }, 300);
            }
            dispatch(
                setAlert({
                    title: res.message
                })
            );
            setSubjectScheduleTmp({
                id: '',
                subjectTeachingId: '',
                subjectId: '',
                startDate: new Date(),
                endDate: new Date(),
                startTime: '07:00',
                endTime: '07:00',
                roomId: '',
                isRepeatedWeekly: false
            });
            setTimeInput({
                startTime: '07:00',
                endTime: '07:00'
            });
            setModalShowSubjectSchedule(false);
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function onSaveContinueSubjectSchedule() {
        if (!subjectScheduleTmp.isRepeatedWeekly) {
            if (setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        } else {
            if (setDatWithTime(subjectScheduleTmp.endDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        }
        try {
            // dispatch(setLoading(true));
            const postData = {
                // @ts-ignore
                subjectTeachingId: subjectScheduleTmp.subjectTeachingId == '' ? classLists[0].id : subjectScheduleTmp.subjectTeachingId,
                startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                endDateTime: setDatWithTime(subjectScheduleTmp.isRepeatedWeekly ? subjectScheduleTmp.endDate : subjectScheduleTmp.startDate, timeInput.endTime),
                // @ts-ignore
                roomId: subjectScheduleTmp.roomId == '' ? rooms[0].id : subjectScheduleTmp.roomId
            };
            if (subjectScheduleTmp.isRepeatedWeekly) {
                const res: any = await $api.schedule.subjectSchedule_createRepeat(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            } else {
                const res: any = await $api.schedule.subjectSchedule_create([postData]);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            }
            setSubjectScheduleTmp({
                ...subjectScheduleTmp,
                id: '',
                subjectTeachingId: '',
                // startDate: subjectTeachingDetail.startDate,
                // endDate: subjectTeachingDetail.startDate,
                startTime: '07:00',
                endTime: '07:00',
                roomId: subjectTeachingDetail.roomIdDefault
                // isRepeatedWeekly: false
            });
            setTimeInput({
                startTime: '07:00',
                endTime: '07:00'
            });
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function isCreateSubjectSchdule(eventName: string) {
        try {
            if (eventName == 'class') {
                setClassSubjectSchedule('');
                subjectScheduleTmp.id = '';
                subjectScheduleTmp.subjectTeachingId = subjectTeachingDetail.id;
                subjectScheduleTmp.startDate = subjectTeachingDetail.startDate;
                subjectScheduleTmp.endDate = subjectTeachingDetail.endDate;
                subjectScheduleTmp.startTime = '07:00';
                subjectScheduleTmp.endTime = '07:00';
                subjectScheduleTmp.roomId = subjectTeachingDetail.roomIdDefault;
                setSubjectScheduleTmp(subjectScheduleTmp);
            } else {
                // @ts-ignore
                setClassSubjectSchedule(eventName);
                subjectScheduleTmp.id = subjectTeachingDetail.id;
                subjectScheduleTmp.subjectTeachingId = subjectTeachingDetail.subjectId;
                subjectScheduleTmp.startDate = subjectTeachingDetail.startDate;
                subjectScheduleTmp.endDate = subjectTeachingDetail.endDate;
                subjectScheduleTmp.startTime = '07:00';
                subjectScheduleTmp.endTime = '07:00';
                subjectScheduleTmp.roomId = subjectTeachingDetail.roomIdDefault;
                setSubjectScheduleTmp(subjectScheduleTmp);
            }
            setModalShowSubjectSchedule(true);
        } catch (e) {
        }
    }

    useEffect(() => {
        async function getTeachers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.teacher.getAll();
                setTeachers(res.result.items);
                if (res.result.length !== 0) {
                    setTeachers(res.result);
                    subjectTeaching.teacherId = res.result[0].id;
                    setSubjectTeaching({
                        ...subjectTeaching
                    });
                } else {
                    setTeachers([]);
                    setSubjectTeaching({
                        ...subjectTeaching,
                        teacherId: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getAll();
                if (res['result'].length !== 0) {
                    setRooms(res.result);
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: res.result[0].id
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getRooms();
        getTeachers();
    }, []);

    function onSave() {
        if (subjectTeaching.name === '') {
            show_ErrMsg('Vui lòng tên lịch học');
            return;
        }
        // @ts-ignore
        if (subjectTeaching.teacherId === '' && teachers[0].id === '') {
            show_ErrMsg('Vui lòng chọn giảng viên');
            return;
        }
        // @ts-ignore
        if (subjectTeaching.roomIdDefault === '' && rooms[0].id === '') {
            show_ErrMsg('Vui lòng chọn phòng học');
            return;
        }
        if (subjectTeaching.endDate < subjectTeaching.startDate) {
            show_ErrMsg('Thời gian kết thúc không hợp lệ');
            return;
        }
        if (updateMode !== null) {
            is_Update();
        } else {
            is_Insert();
        }
    }

    async function is_Update() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_update(
                subjectTeaching
            );
            if (res) {
                dispatch(
                    setAlert({
                        title: `;
                                Cập;
                                nhật;
                                lịch;
                                học;
                                thành;
                                công`
                    })
                );
            }
            setModalShow(false);
            subjectTeaching.name = '';
            subjectTeaching.subjectId = '';
            subjectTeaching.startDate = new Date();
            subjectTeaching.endDate = new Date();
            subjectTeaching.teacherId = '';
            subjectTeaching.roomIdDefault = '';
            setSubjectTeaching(subjectTeaching);
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function is_Insert() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_create(
                subjectTeaching
            );
            if (res) {
                dispatch(
                    setAlert({
                        title: `;
                                Tạo;
                                lịch;
                                học;
                                thành;
                                công`
                    })
                );
            }
            setModalShow(false);
            subjectTeaching.name = '';
            subjectTeaching.subjectId = '';
            subjectTeaching.startDate = new Date();
            subjectTeaching.endDate = new Date();
            subjectTeaching.teacherId = '';
            subjectTeaching.roomIdDefault = '';
            setSubjectTeaching(subjectTeaching);
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function goToSchedule(id) {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
        history.push(`/lich-hoc-mon-chitiet/${id}`);
    }

    async function getClass(id) {
        setSubjectScheduleTmp({
            ...subjectScheduleTmp,
            subjectId: id
        });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_getPaging({
                subjectId: id
            });
            if (res['result'].items.length > 0) {
                setClassLists(res.result.items);
                setSubjectScheduleTmp({
                    ...subjectScheduleTmp,
                    subjectId: id,
                    subjectTeachingId: res.result.items[0].id,
                    startDate: res.result.items[0].startDate,
                    endDate: res.result.items[0].endDate
                });
            } else {
                setClassLists([]);
                setSubjectScheduleTmp({
                    ...subjectScheduleTmp,
                    subjectTeachingId: ''
                });
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã xảy ra lỗi vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function changeClass(id: string) {
        setSubjectScheduleTmp({
            ...subjectScheduleTmp,
            subjectTeachingId: id,
            // @ts-ignore
            startDate: classLists.find(// @ts-ignore
                (item) => item.id === id).startDate,
            // @ts-ignore
            endDate: classLists.find(// @ts-ignore
                (item) => item.id === id).endDate
        });
    }

    function goAttendance(subjectSchedule) {
        history.push('/diem-danh?id=' + subjectSchedule.id);
    }

    function exportSubjectSch() {
        setModalShowExportSubjectSchedule(true);
    }

    async function exportSubjectSchedules() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedulesExport(subjectScheduleExport);
            if (res) {
                // @ts-ignore
                fileDownload(res, `TKB_KHOA_${faculties.find(fac => fac.id == faculty)?.name}_${subjectScheduleExport.teacherId ? teachers.find(tech =>
                    // @ts-ignore
                    tech.id == subjectScheduleExport.teacherId)?.nameAndFacultyName : 'TAT_CA_GIANG_VIEN'}_TU_${moment(subjectScheduleExport.startDate).format('DD/MM/YYYY')}_DEN_${moment(subjectScheduleExport.endDate).format('DD/MM/YYYY')}.xlsx`);
                setSubjectScheduleExport({
                    startDate: new Date(),
                    endDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
                    teacherId: ''
                });
            }
            dispatch(setLoading(false));
            setModalShowExportSubjectSchedule(false);
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function searchSubject() {
        getSubjects({
            facultyId: faculty || faculty.length > 0 ? faculty : null,
            freeTextSearch: freeTextSearch || freeTextSearch.length > 0 ? freeTextSearch : null
        });
        getSubjects_GetLists();
    }


    // @ts-ignore
    return (
        <DefaultLayout>
            <div className='subject page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div>Danh sách môn học</div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='flex justify-between items-end'>
                            <div className='mr-4'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Khoa
                                </div>
                                <select
                                    className='w-60 block px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                    value={faculty}
                                    onChange={(e) => {
                                        setFaculty(e.target.value);
                                    }}>
                                    {faculties.map((item: any) => {
                                        return (
                                            <option
                                                value={item.id}
                                                key={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {
                                role ? <div
                                    className='actions--item'
                                    onClick={(e) => openCreateSubject(e)}>
                                    <Button
                                        label='Thêm môn học'
                                        icon={<i className='fas fa-user-plus' />}
                                    />
                                </div> : ''
                            }
                        </div>
                        <div />
                        <div className={'actions flex justify-end align-bottom h-100 mt-4'}>
                            <input
                                name='search'
                                type='text'
                                required
                                className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder={
                                    'Tìm kiếm môn học '
                                }
                                value={freeTextSearch}
                                onChange={(e) => setFreeTextSearch(e.target.value)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && searchSubject()
                                }
                            />
                            <div onClick={searchSubject}>
                                <Button
                                    label='Tìm kiếm'
                                    color='bg-yellow-500'
                                    hoverColor='bg-yellow-400'
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            subjects.length === 0 ?
                                <div className='flex flex-wrap -ml-4 -mb-4'
                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <img src='/notFound_01.png'
                                         alt='Không tìm thấy dữ liệu'
                                         title='Không tìm thấy dữ liệu'
                                    />
                                </div>
                                :
                                <div className='subject--list flex flex-wrap -ml-4 -mb-4'>
                                    {subjects.map((fa: any) => {
                                        return (
                                            <div
                                                key={fa.id}
                                                className='subject--item ml-4 mb-4 font-bold flex
												items-center justify-center bg-green-500 text-white
												hover:bg-green-600'
                                                onClick={(e) => goTeaching(e, fa.id, fa.facultyId)}>
                                                <div className='name text-center'>
                                                    <div>{fa.name}</div>
                                                    <div className='font-normal text-xs'>
                                                        (Mã môn: {fa.subjectCode} - Tín
                                                        chỉ: {fa.creditPoint})
                                                    </div>
                                                </div>
                                                {role ? <div className='actions flex justify-center'>
                                                    <div
                                                        className='delete actions--item'
                                                        onClick={(e) =>
                                                            deleteFaculties(e, fa.id)
                                                        }>
                                                        <i className='fas fa-trash' />
                                                    </div>
                                                    <div
                                                        className='edit actions--item ml-4'
                                                        onClick={(e) =>
                                                            openUpdateSubject(e, fa)
                                                        }>
                                                        <i className='fas fa-pen' />
                                                    </div>
                                                </div> : ''}
                                            </div>
                                        );
                                    })}
                                </div>
                        }

                    </div>
                    <Modal
                        show={modalShow}
                        aria-labelledby='contained-modal-title-vcenter'
                        onHide={() => setModalShow(false)}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id='contained-modal-title-vcenter'>
                                {updateMode
                                    ? 'Cập nhật môn học'
                                    : 'Tạo mới môn học'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Tên môn học
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    required
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Nhập tên môn học'
                                    value={subject.name}
                                    onChange={(e) => {
                                        setSubject({
                                            ...subject,
                                            name: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Mã môn học
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    required
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Nhập mã môn học'
                                    value={subject.subjectCode}
                                    onChange={(e) => {
                                        setSubject({
                                            ...subject,
                                            subjectCode: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Số tín chỉ
                                </div>
                                <input
                                    name='text'
                                    type='number'
                                    required
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Nhập tính chỉ'
                                    value={subject.creditPoint}
                                    onChange={(e) => {
                                        setSubject({
                                            ...subject,
                                            creditPoint: Number(e.target.value)
                                        });
                                    }}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div
                                onClick={() => {
                                    updateMode
                                        ? updateSubject(updateMode)
                                        : createSubject();
                                }}>
                                <Button
                                    label={updateMode ? 'Lưu lại' : 'Tạo mới'}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                {role ? <div className='page__content bg-white mt-3 subject-detail'>
                    <div className='action--item flex align-items-center justify-content-start'>
                        <div
                            className='actions--item'
                            onClick={(e) => {
                                isCreateSubjectSchdule('faculty');
                            }}>
                            <Button
                                label='Tạo thời khóa biểu mới'
                                icon={<i className='fas fa-calendar-plus' />}
                            />
                        </div>
                        <div
                            className='actions--item ml-2'
                            onClick={(e) => {
                                exportSubjectSch();
                            }}>
                            <Button
                                color={'bg-green-800'}
                                hoverColor={'bg-green-900'}
                                label='Xuất thời khóa biểu'
                                icon={<i className='fas fa-file' />}
                            />
                        </div>
                    </div>
                </div> : ''}
                <div className='page__content bg-white mt-3'>
                    <Calendar
                        data={Array.prototype.concat(calendarSubject, calendarExam)}
                        isEventClicked={id => getSubjectTeaching(id)}
                        isEventDateClicked={dateStr => getSubjectOnDate(dateStr)}
                        isEventChangeMonthClicked={(startStr, endStr) => setIsMonth({
                            start: startStr,
                            end: endStr
                        })}
                    />
                </div>
                {
                    // @ts-ignore
                    subjectOnDay.length > 0 ? <div className='page__content bg-white mt-3' ref={ref}>
                        <TableSubjectOnDay data={subjectOnDay}
                                           clicked={(e, subjectTeachingId) => goToSchedule(subjectTeachingId)} />
                    </div> : ''
                }
            </div>
            <div>
                <Modal
                    show={modalShowSubjectSchedule}
                    aria-labelledby='contained-modal-title-vcenter'
                    onHide={() => setModalShowSubjectSchedule(false)}
                    centered>
                    <Modal.Header closeButton>
                        {
                            // @ts-ignore
                            !classSubjectSchedule?.length > 0 ? <Modal.Title id='contained-modal-title-vcenter'>
                                <div className={'flex flex-column'}>
                                    Cập nhật lịch học
                                </div>
                            </Modal.Title> : <Modal.Title id='contained-modal-title-vcenter'>
                                <div className={'flex flex-column'}>
                                    Tạo mới lịch học
                                </div>
                            </Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {
                            classSubjectSchedule.length ? <div className='flex center'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Lặp lại hằng tuần
                                </div>
                                <input
                                    type='checkbox'
                                    className='h-4 w-4 mt-0.5 ml-4'
                                    // @ts-ignore
                                    checked={subjectScheduleTmp.isRepeatedWeekly}
                                    onChange={(e) => {
                                        isRepeatedWeekly_Checked(e);
                                    }}
                                />
                            </div> : <div>
                                <div className='mt-2 w-100'>
                                    <div className='pl-1 font-semibold mb-2 text-gray-600 '>
                                        Khoa: <span className={'text-danger'}>{
                                        // @ts-ignore
                                        subjectsDetails.data.subjectTeaching.subject?.facultyName}</span>
                                    </div>
                                </div>
                                <div className='mt-2 w-100'>
                                    <div className='pl-1 font-semibold mb-2 text-gray-600 '>
                                        Môn: <span className={'text-danger'}>{
                                        // @ts-ignore
                                        subjectsDetails.data.subjectTeaching.subject?.name}</span>
                                    </div>
                                </div>
                                <div className='mt-2 w-100'>
                                    <div className='pl-1 font-semibold mb-2 text-gray-600 '>
                                        Lớp: <span className={'text-danger'}>{
                                        // @ts-ignore
                                        subjectsDetails.data.subjectTeaching?.name}</span>
                                    </div>
                                </div>
                            </div>
                        }

                        {subjects && subjects.length > 0 ? (
                            // @ts-ignore
                            classSubjectSchedule.length ?
                                <div className='mt-2 w-100'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Môn học
                                    </div>
                                    <select
                                        className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                        // @ts-ignore
                                        value={subjectScheduleTmp.subjectId}
                                        onChange={(e) => {
                                            getClass(e.target.value);
                                        }}>
                                        {subjects.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div> : ''
                        ) : (
                            ''
                        )}
                        {
                            // @ts-ignore
                            classSubjectSchedule.length ?
                                <div className='mt-2 w-100'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Lớp học
                                    </div>
                                    <select
                                        className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                        // @ts-ignore
                                        value={subjectScheduleTmp.subjectTeachingId}
                                        onChange={(e) => {
                                            changeClass(e.target.value);
                                        }}>
                                        {classLists?.length > 0 ? classLists.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        }) : <option
                                            value={''}
                                            key={''}>
                                            Chọn môn học để lấy danh sách lớp
                                        </option>}
                                    </select>
                                </div> : ''
                        }
                        {
                            classSubjectSchedule.length ? <div>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        {subjectScheduleTmp.isRepeatedWeekly ? 'Từ ngày' : 'Ngày học'}
                                    </div>
                                    <DatePicker
                                        className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        selected={new Date(subjectScheduleTmp.startDate)}
                                        onChange={(date) => {
                                            setSubjectScheduleTmp({
                                                ...subjectScheduleTmp,
                                                startDate: date
                                            });
                                        }}
                                        minDate={new Date(subjectScheduleTmp.startDate)}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </div>
                                {
                                    !subjectScheduleTmp.isRepeatedWeekly ? '' :
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Đến ngày
                                            </div>
                                            <DatePicker
                                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                selected={new Date(subjectScheduleTmp.endDate)}
                                                onChange={(date) => {
                                                    setSubjectScheduleTmp({
                                                        ...subjectScheduleTmp,
                                                        endDate: date
                                                    });
                                                }}
                                                minDate={new Date(subjectScheduleTmp.endDate)}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        </div>
                                }
                            </div> : <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày học
                                </div>
                                <DatePicker
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    selected={new Date(subjectScheduleTmp.startDate)}
                                    onChange={(date) => {
                                        setSubjectScheduleTmp({
                                            ...subjectScheduleTmp,
                                            startDate: date
                                        });
                                    }}
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                        }

                        {rooms && rooms.length > 0 ? (
                            <div className='mt-2'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Phòng học
                                </div>
                                <select
                                    className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                    // @ts-ignore
                                    value={subjectScheduleTmp.roomId}
                                    onChange={(e) => {
                                        setSubjectScheduleTmp({
                                            ...subjectScheduleTmp,
                                            roomId: e.target.value
                                        });
                                    }}>
                                    {rooms.map((item: any) => {
                                        return (
                                            <option
                                                value={item.id}
                                                key={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        ) : (
                            ''
                        )}
                        <div style={{ paddingTop: '20px' }}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Giờ bắt đầu
                            </div>
                            <input
                                name='text'
                                type='time'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Giờ bắt đầu buổi học'
                                onChange={(e) => {
                                    e.target.value == '07:30' ?
                                        setTimeInput({
                                            ...timeInput,
                                            startTime: e.target.value,
                                            endTime: '10:45'
                                        }) : e.target.value == '13:30' ? setTimeInput({
                                            ...timeInput,
                                            startTime: e.target.value,
                                            endTime: '16:45'
                                        }) : setTimeInput({
                                            ...timeInput,
                                            startTime: e.target.value
                                        });
                                }}
                                value={timeInput.startTime}
                            />
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Giờ kết thúc
                            </div>
                            <input
                                name='text'
                                type='time'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Giờ kết thúc'
                                onChange={(e) => {
                                    setTimeInput({
                                        ...timeInput,
                                        endTime: e.target.value
                                    });
                                }}
                                min={timeInput.startTime}
                                value={timeInput.endTime}
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between' }}>
                        {
                            // @ts-ignore
                            classSubjectSchedule.length ?
                                <div className={'flex flex-row justify-content-between w-100'}>
                                    <div
                                        onClick={() => {
                                            onSaveSubjectSchedule();
                                        }}>
                                        <Button icon={<i className='fad fa-save'></i>}
                                                label={'Lưu & Đóng'} />
                                    </div>
                                    <div
                                        onClick={() => {
                                            onSaveContinueSubjectSchedule();
                                        }}>
                                        <Button icon={<i className='fas fa-share-all'></i>}
                                                label='Lưu & Tiếp tục' />
                                    </div>
                                </div> :
                                <>
                                    {
                                        role ? <div className={'flex flex-row justify-content-between w-100'}>
                                            <div
                                                onClick={() => {
                                                    isDeleteSubjectSchedule();
                                                }}>
                                                <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                                        icon={<i className='fas fa-trash'></i>} label='Xóa buổi học' />
                                            </div>
                                            <div
                                                onClick={() => {
                                                    onUpdateSubjectSchedule();
                                                }}>
                                                <Button icon={<i className='fas fa-save'></i>}
                                                        label={'Lưu lại'} />
                                            </div>
                                        </div>: <></>
                                    }
                                </>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal
                    show={modalShowExportSubjectSchedule}
                    aria-labelledby='contained-modal-title-vcenter'
                    onHide={() => setModalShowExportSubjectSchedule(false)}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id='contained-modal-title-vcenter'>
                            <div className={'flex flex-column'}>
                                Xuất thời khóa biểu
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mt-2 w-100'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Giảng viên
                            </div>
                            <select
                                className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                // @ts-ignore
                                value={subjectScheduleExport.teacherId}
                                onChange={(e) => {
                                    setSubjectScheduleExport({ ...subjectScheduleExport, teacherId: e.target.value });
                                }}>
                                <option
                                    value={''}>
                                    Tất cả giảng viên
                                </option>
                                {teachers?.length > 0 ? teachers.filter(record =>
                                    // @ts-ignore
                                    record.facultyId == faculty).map((item: any) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={item.id}>
                                            {item.nameAndFacultyName}
                                        </option>
                                    );
                                }) : <option
                                    value={''}
                                    key={''}>
                                    Chọn chọn giảng viên
                                </option>}
                            </select>
                        </div>
                        <div>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ngày bắt đầu
                            </div>
                            <DatePicker
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                selected={new Date(subjectScheduleExport.startDate)}
                                onChange={(date) => {
                                    setSubjectScheduleExport({
                                        ...subjectScheduleExport,
                                        startDate: date
                                    });
                                }}
                                // minDate={new Date(subjectScheduleExport.startDate)}
                                dateFormat='dd/MM/yyyy'
                            />
                        </div>
                        <div>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ngày kết thúc
                            </div>
                            <DatePicker
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                selected={new Date(subjectScheduleExport.endDate)}
                                onChange={(date) => {
                                    setSubjectScheduleExport({
                                        ...subjectScheduleExport,
                                        endDate: date
                                    });
                                }}
                                minDate={new Date(subjectScheduleExport.startDate)}
                                dateFormat='dd/MM/yyyy'
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between' }}>
                        <div className={'flex flex-row justify-content-between w-100'}>
                            <div
                                onClick={() => {
                                    setModalShowExportSubjectSchedule(false);
                                }}>
                                <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                        icon={<i className='fas fa-times-circle'></i>} label='Hủy' />
                            </div>
                            <div
                                onClick={() => {
                                    exportSubjectSchedules();
                                }}>
                                <Button icon={<i className='fas fa-file-alt'></i>}
                                        label={'Xuất excel'} />
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />

            <ConfirmDialog
                confirmDialog={confirmDeleteSubjectDialog}
                setConfirmDialog={setConfirmDeleteSubjectDialog}
                deleteItem={deleteSubjectSchedule}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default Subject__List;
